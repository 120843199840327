import React, { useEffect, useState } from "react"
import { Col, Container, Row } from "react-bootstrap"

import StaticShare from "../StaticShare/StaticShare"
import CareerDetailSidebar from "./CareerDetailSidebar"
import "./assets/styles/_index.scss"

const {
  ContentModule,
} = require("@starberry/gatsby-theme-utils/dist/Modules/ContentModule")

const CareerDetailLanding = (props) => {
  // Sticky scroll
  const [scroll, setScroll] = useState(false)

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 250)
    })
  }, [])
  // Sticky scroll
  
  return (
    <div className="career-detail-wrapper">
      <Container>
        <Row>
          <Col xl={7}>
            <div className="career-detail-intro">
              <h1>{props.title}</h1>
              <div className="career-detail-list d-flex align-items-center">
                {props.location && (
                  <p className="button-with-icon">
                    <i className="icon icon-location" />
                    {props.location}
                  </p>
                )}
                {props.job_type && <span>/</span>}
                {props.job_type && (
                  <p className="button-with-icon">
                    <i className="icon icon-clock" />
                    {/* {props.categories?.map((item, i) => {
                      return (
                        <>
                          {item.blog_name}{" "}
                          {i === props?.categories?.length - 1 ? null : "/ "}
                        </>
                      )
                    })} */}
                    {props?.job_type}
                  </p>
                )}
              </div>
            </div>
          </Col>
          <Col xl={5} />
          <Col xl={7}>
            <div className="career-detail-desc">
              <ContentModule Content={props.job_details?.data.job_details} />
            </div>

            <StaticShare title="Share this job" />
            <div className="d-xl-none d-block">
              <CareerDetailSidebar
                title={props.title}
                author={props.team ? props.team : ""}
                globalContent={props?.globalContent}
              />
            </div>
          </Col>
          <Col xl={1} />
          <Col xl={4} className="d-xl-block d-none sidebar-column">
            <div className={`career-sidebar  ${scroll ? "scrolled" : ""}`}>
              <CareerDetailSidebar
                title={props.title}
                author={props.team ? props.team : ""}
                globalContent={props?.globalContent}
              />
            </div>
          </Col>
          {/* <div className="divider-line" /> */}
        </Row>
      </Container>
    </div>
  )
}

export default CareerDetailLanding
