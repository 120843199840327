import { graphql } from "gatsby"
import React from "react"
import { PageLinks } from "../common/site/page-static-links"
import BreadcrumbModule from "../components/BreadcrumbModule/BreadcrumbModule"
import CareerDetailLanding from "../components/CareerDetailLanding/CareerDetailLanding"
import FloatingButton from "../components/FloatingButton/FloatingButton"
import Layout from "../components/layout"
import Seo from "../components/seo"

const CareerDetails = ({ data }) => {
  const PageData = data?.strapiCareer
  const globalContent =
  data?.allStrapiGlobalContent?.edges?.length > 0 &&
  data?.allStrapiGlobalContent?.edges[0]
  let breadcrumData
  // if (PageLinks?.career_parent_label) {
  breadcrumData = {
    parentlabel: "Contact",
    parentlink: PageLinks?.contact,
    subparentlabel: "Careers",
    subparentlink: PageLinks?.career,
    title: PageData?.title,
  }
  // } else {
  //   breadcrumData = {
  //     parentlabel: "Careers",
  //     parentlink: PageLinks?.career,
  //     title: PageData?.title,
  //   }
  // }

  return (
    <Layout dark footerClass="footer-cta" footerContact="footercontact">
      <div className="layout-padding-top" />
      <div className="pattern-bg">
        <BreadcrumbModule {...breadcrumData} />
        <div className="layout-grey-gradient-bg">
          <CareerDetailLanding {...PageData} globalContent={globalContent} />
        </div>
        <FloatingButton
          title={PageData?.title}
          call={PageData?.team?.phone}
          tag="career-detail"
        />
      </div>
    </Layout>
  )
}

export const Head = ({ data }) => {
  const PageData = data?.strapiCareer
  const metaDescription = `Join our dynamic team as a ${PageData?.title} at ${process.env.GATSBY_SITE_NAME}. Take the first step towards a rewarding real estate career with our established agency. Apply now!`

  return <Seo title={PageData?.title} description={metaDescription} />
}

export default CareerDetails

export const query = graphql`
  query ($page_id: Int) {
    strapiCareer(strapi_id: { eq: $page_id }) {
      ...CareerFragment
      job_details {
        data {
          job_details
        }
      }
      team {
        name
        designation
        email
        phone
        strapi_id
      }
    }
    allStrapiGlobalContent {
      edges {
        node {
          career_team_content {
            data {
              career_team_content
            }
          }
          career_team_title
        }
      }
    }
  }
`
