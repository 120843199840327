import loadable from "@loadable/component"
import React, { useState } from "react"
import { Modal } from "react-bootstrap"
import CareerFormFields from "../../../static/forms/careers_form.json"
import NegoCard from "../NegoCard/NegoCard"

const DefaultForm = loadable(() => import("../forms/default-form-layout"))
const {
  ContentModule,
} = require("@starberry/gatsby-theme-utils/dist/Modules/ContentModule")
const CareerDetailSidebar = (props) => {
  const [show, setShow] = useState(false)
  const handleModal = () => {
    setShow(true)
  }

  const closeModal = () => {
    setShow(false)
  }
  return (
    <div className="career-detail-sidebar-wrapper">
      <div className="sidebar-pattern">
        <h3>{props?.globalContent?.node?.career_team_title}</h3>
        <ContentModule
                    Content={
                      props?.globalContent?.node?.career_team_content?.data
                        ?.career_team_content
                    }
                  />
        <div className="d-none d-xl-block">
          <a
            href="javascript:void(0);"
            onClick={() => handleModal()}
            className="button button-skyblue button-with-icon"
          >
            <i className="icon icon-email-color" />
            Apply now
          </a>
        </div>
      </div>
      <NegoCard author={props.author} />
      <Modal
        show={show}
        onHide={() => setShow(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="more-filters-modal team-form"
        id="filter-modal"
        // fullscreen={"xl-down"}
        backdrop="static"
      >
        <button
          className="cancel-button"
          type="button"
          onClick={() => closeModal()}
        >
          <i className="icon black-cancel" />
        </button>
        <Modal.Body>
          <div className="modal-body-container">
            <h2>Apply for this job</h2>
            <DefaultForm
              fields={CareerFormFields}
              formtagclassname="team-form"
              // to_email_id={}
              job={props?.title}
              pageTitle={`Careers- ${props?.title}`}
            />
          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default CareerDetailSidebar
