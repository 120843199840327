import loadable from "@loadable/component"
import { Link } from "gatsby"
import React, { useState } from "react"
import { Modal } from "react-bootstrap"
import TeamFormFields from "../../../static/forms/team_form.json"
import { PageLinks } from "../../common/site/page-static-links"
import { useAllStrapiTeam } from "../../hooks/use-all-strapiteam"
import NoImage from "../../images/no-image.png"
import "./assets/styles/_index.scss"

const DefaultForm = loadable(() => import("../forms/default-form-layout"))
const {
  ImageModule,
} = require("@starberry/gatsby-theme-utils/Modules/ImageModule")

const NegoCard = ({ author }) => {
  const [show, setShow] = useState(false)
  const handleModal = () => {
    setShow(true)
  }

  const closeModal = () => {
    setShow(false)
  }

  const imagename = "team.image.propdetails_image"

  let team_data = useAllStrapiTeam()
  team_data = team_data.allStrapiTeam.nodes
  let team_key
  if (author) {
    for (const k in team_data) {
      if (author?.strapi_id == team_data[k].strapi_id) {
        team_key = k
        break
      }
    }
  }

  return (
    <>
      {team_data[team_key] && (
        <div className="nego-wrapper">
          <div className="img-wrapper-section img-wrapper">
            {/* {author?.image?.url} */}
            {team_data[team_key]?.image ? (
              <Link to={`/${PageLinks.team}/${team_data[team_key]?.slug}/`}>
              <ImageModule
                ImageSrc={team_data[team_key]?.image}
                altText={
                  team_data[team_key]?.image?.alternativeText || author.name
                }
                imagetransforms={
                  team_data[team_key]?.imagetransforms?.imagetransforms
                }
                renderer="srcSet"
                imagename={imagename}
                strapi_id={team_data[team_key]?.strapi_id}
                classNames="img-fluid"
              /></Link>
            ) : (
              <Link to={`/${PageLinks.team}/${team_data[team_key]?.slug}/`}><img src={NoImage} className="img-fluid" alt="" /></Link>
            )}
          </div>
          <div className="text-wrapper">
            <div className="nego-name"><Link to={`/${PageLinks.team}/${team_data[team_key]?.slug}/`}>{team_data[team_key].name}</Link></div>
            <p className="nego-role">{team_data[team_key].designation}</p>
            <div className="nego-list">
              {team_data[team_key].brn_number && (
              <a
                //  href={`tel:${author.phone}`}
                className="phone"
              >
                <span>BRN No: {team_data[team_key].brn_number}</span>
              </a>
              )} 
              {team_data[team_key].phone && <span>/</span>}
              <i className="icon icon-envelope" />
              <a
                onClick={() => handleModal()}
                className="link-underline nego-email"
              >
                Email
              </a>
            </div>
          </div>
          <Modal
            show={show}
            onHide={() => setShow(false)}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className="more-filters-modal team-form"
            id="filter-modal"
            // fullscreen={"xl-down"}
            backdrop="static"
          >
            <button
              className="cancel-button"
              type="button"
              onClick={() => closeModal()}
            >
              <i className="icon black-cancel" />
            </button>
            <Modal.Body>
              <div className="modal-body-container">
                <h2>Email to {team_data[team_key].name}</h2>
                <DefaultForm
                  fields={TeamFormFields}
                  formtagclassname="team-form"
                  to_email_id={team_data[team_key].email}
                />
              </div>
            </Modal.Body>
          </Modal>
        </div>
      )}
    </>
  )
}

export default NegoCard
